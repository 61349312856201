<template>
  <div class="previewPage">
    <pdf v-for="item in numPages" :key="item" :src="src" :page="item" />
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      loaded: false,
      numPages: "",
      src: "",
      item: this.$route.query.item,
    };
  },
  mounted() {
    this.loadPdf(this.item.fileUrl);
  },
  methods: {
    // pdf加载时
    loadPdf(url) {
      this.src = pdf.createLoadingTask(url);
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages; // 这里拿到当前pdf总页数
      });
    },
  },
};
</script>

<style scoped lang="scss">
.previewPage {
  width: 100%;
  height: 100vh;
}
</style>